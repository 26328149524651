import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LogService } from '@core/services';
import { InfoMessagesService } from '@features/info-messages';
import { catchError, throwError } from 'rxjs';

export const httpErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const infoMessagesService = inject(InfoMessagesService);
  const log = inject(LogService);
  const router = inject(Router);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      switch (error.status) {
        case 0:
          infoMessagesService.showError('API connection error');
          break;
        case HttpStatusCode.Unauthorized:
          if (error.error.code === 'user_not_found' && !req.url.includes('/me')) {
            router.navigate(['/auth/logout']);
          }
          break;
        case HttpStatusCode.ExpectationFailed:
        case HttpStatusCode.NotFound:
        case HttpStatusCode.BadRequest:
          break;
        case HttpStatusCode.Forbidden:
          break;
        default:
          log.warn('Unhandled HTTP error: ', error);
      }
      return throwError(() => error);
    }),
  );
};
