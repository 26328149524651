import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { IdleDialogComponent } from './components/idle-dialog/idle-dialog.component';
import { IdleComponent } from './components/idle/idle.component';
import { IdleService } from './services/idle/idle.service';
import { IdleTokenService } from './services/idle-token/idle-token.service';

@NgModule({
  declarations: [IdleComponent, IdleDialogComponent],
  providers: [IdleService, IdleTokenService],
  exports: [IdleComponent],
  imports: [CommonModule, SharedModule],
})
export class IdleModule {}
